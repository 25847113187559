import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import './style.scss'

class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const tags = post.fields.tagSlugs
    const category = post.frontmatter.category
    const categorySlug = post.fields.categorySlug
    const description = post.frontmatter.description
    const readingTime = post.fields.readingTime.text
    const postLanguage = post.frontmatter.language

    const homeBlock = (
      <div>
        <Link className="post-single__home-button" to="/" lang="en">
          All Articles
        </Link>
      </div>
    )

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tags &&
            tags.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
    )

    return (
      <div>
        {homeBlock}
        <div className="post-single" lang={postLanguage}>
          <div className="post-single__inner">
            <h1 className="post-single__title">{post.frontmatter.title}</h1>
    
            <div className="post-single__meta post__meta">
              <span className="post-single__meta post__meta-time" lang="en">
                {readingTime}
              </span>
              <span className="post-single__meta post__meta-divider" />            
              <time
                className="post-single__meta post__meta-time"
                dateTime={moment(post.frontmatter.date).format('MMMM D, YYYY')}
              >
                {moment(post.frontmatter.date).format('DD MMM YYYY')}
              </time>
              <span className="post-single__meta post__meta-divider" />
              <span className="post-single__meta post__meta-category" key={categorySlug}>
                <Link to={categorySlug} className="post-single__meta post__meta-category-link">
                  {category}
                </Link>
              </span>
            </div>

            <div className="post-single__body post-single__description">
              <p>{description}</p>
            </div>

            <div
              className="post-single__body"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
            />

          </div>
          <div className="post-single__footer">
            {tagsBlock}
            <hr />
            <p className="post-single__footer-text">
              <strong>{author.name}</strong> <br />
              {subtitle}
              <a
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <br /> @newtoncalegari
              </a>
            </p>
            {commentsBlock}
          </div>
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
